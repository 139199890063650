import React from 'react';
import Button from "../elements/Button";
import Radio from "../elements/Radio";
import Input from "../elements/Input";
import Checkbox from "../elements/Checkbox";
import Select from "../elements/Select";
import GenericSection from "./GenericSection";
import './offerte.css'

class Offerte extends React.Component {
    render() {

        const sectionHeader = {
            title: 'Offerte'
        }

        return (
            <GenericSection>
                <div id={'offerte'}>
                <div id={'pads-h'}>
                    <div id={'pads-t'}><h2 class="mt-0 mb-16">Offerte aanvragen</h2></div>
                    <form action="https://www.w3schools.com/action_page.php" target={"_blank"}>
                        <fieldset>
                            <div className="mb-16">
                                <Input id={"name"} name={"name"}
                                    type="text"
                                    placeholder="A Renovaties"
                                    label="Naam"/>
                            </div>
                            <div className="mb-16">
                                <Input id={"email"} name={"email"}
                                    type="email"
                                    placeholder="Email"
                                    placeholder="info@arenovaties.be"
                                    label="Email"
                                    hint="Wij zullen u contacteren op dit emailadres"
                                    required/>
                            npm </div>
                            <div className="mb-16">
                                <Input id={"message"} name={"message"}
                                    type="textarea"
                                    placeholder="type hier uw bericht"
                                    label="Bericht"/>
                            </div>
                            <div className="mb-16">
                                <Checkbox required>Ik ga ermee akkoord dat er een antwoord wordt gestuurd naar het ingevulde email adres</Checkbox>
                            </div>
                            <div className="mb-16">

                            </div>
                            <div className="mt-24">
                                <div className="button-group">
                                    <Button wideMobile color="primary" tag="input" type="submit" value="Versturen" />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <div id={'pads-b'}> </div>
                </div>
                </div>
            </GenericSection>
        );
    }
}

export default Offerte;
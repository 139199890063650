import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const Contact = ({
                     className,
                     topOuterDivider,
                     bottomOuterDivider,
                     topDivider,
                     bottomDivider,
                     hasBgColor,
                     invertColor,
                     invertMobile,
                     invertDesktop,
                     alignTop,
                     imageFill,
                     ...props
                 }) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Contact',
        paragraph: 'U kan ons steeds bereiken via email, telefoonnummer of via het offerte formulier op onze site. Wij hopen snel iets van u te horen.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left"
                                 data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    Altijd en overal
                                </div>
                                <h3 className="mt-0 mb-12">
                                    Ons bereiken
                                </h3>
                                <p className="m-0">
                                    <h6>Adres: </h6>
                                    Domeinstraat 11 <br/>
                                    3010 Kessel-lo
                                    <h6>Telefoon:</h6>
                                    0475/890802
                                    <h6>E-mail:</h6>
                                    info@arenovaties.be
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                 data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/contact-us-customer-support-inquiry-600w-447727018.webp')}
                                    alt="Features split 01"
                                    width={528}
                                    height={396}/>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
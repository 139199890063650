import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Offerte from "../components/sections/Offerte";
import ContactInfo from "../components/sections/ContactInfo";

const Contact = () => {

    return (
        <>
            <ContactInfo invertMobile topDivider imageFill className="illustration-section-02" />
            <Offerte layout={'default-layout'}/>
        </>
    );
}

export default Contact;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Wij bieden u graag volgende diensten aan',
    paragraph: 'Onze mensen hebben expertise in vele gebieden, en daarom kunnen wij u helpen bij vrijwel al uw renovatie klussen!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div id='diensten' className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Van het plaatsen tot het renoveren tot het afbreken
                  </div>
                <h3 className="mt-0 mb-12">
                  Badkamers
                  </h3>
                <p className="m-0">
                  Wij helpen u graag met uw badkamer! Voor zowel het plannen en/of het bouwen van uw badkamer kunt u bij ons terecht! Omdat wij zowel sanitair als elektriciteit doen hoeft u niemand anders meer in te huren. Zo bent u zeker van een goede tijdsplanning en de beste integratie!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/bathroom1.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Van een kleine kamer tot een hele appartementsblok
                  </div>
                <h3 className="mt-0 mb-12">
                  Verven
                  </h3>
                <p className="m-0">
                  Verven is voor velen een lastige en tijdsrovende karwij. Wij zorgen voor een professioneel resultaat, zodat u u geen zorgen hoeft te maken!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/painting.webp')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Een muur, een plafond, een vloer, een terras, ...
                  </div>
                <h3 className="mt-0 mb-12">
                  Bouwen en renoveren
                  </h3>
                <p className="m-0">
                  Wij zorgen voor de plaatsing of herstelling van muren, deuren, plafonds, vloeren, terassen, ramen, en nog meer. Onze kennis van elektriciteit en sanitair is een extra troef! Zo kunnen we meteen de buizen en draden praten.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/renovation.webp')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Van het plaatsen tot het renoveren tot het afbreken
                </div>
                <h3 className="mt-0 mb-12">
                  Keukens
                </h3>
                <p className="m-0">
                  Wij zorgen voor uw keuken helemaal zoals u dat wil! Met onze kasten op maat past de keuken perfect in uw woning en is er geen verloren ruimte.
                </p>
              </div>
              <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                   data-reveal-container=".split-item">
                <Image
                    src={require('./../../assets/images/kitchen.webp')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Maatkasten, design, planning, ...
                </div>
                <h3 className="mt-0 mb-12">
                  Andere plannen?
                </h3>
                <p className="m-0">
                  U wilt graag iets anders? Laat ons zeker weten wat u wilt! Wij kijken graag mee wat we voor u kunnen doen.
                </p>
              </div>
              <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                   data-reveal-container=".split-item">
                <Image
                    src={require('./../../assets/images/need-more-space-concept-goldfish-600w-728372146.webp')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;